/* DARK MODE */
/* App page containers */
.navbar-image {
    filter: invert(100%);
    width: 60%;
    height: 80%;
    margin-left: 15%;
}

.toggle-icon {
    color: white;
}

.nav-main-container {
    background-color: #1E1A48;
}

.nav-container {
    margin-left: 6%;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.nav-container li a {
    color: white;
    display: inline-block;
}

.nav-container li a:hover {
    color: white;
}

.nav-container li {
    width: 20%;
    height: 80%;
    text-align: center;
    font-size: 20px;
}

.nav-container li:hover {
    color: white;
    background-color: #36365e;
    border-radius: 15px;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.home-container {
    width: 100%;
    background-color: #110F2B;
    display: flex;
    justify-content: space-evenly;
    color: white;
}

.about-container {
    background-color: #323061;
}

.exp-container {
    background-color: #323061;
}

.project-container {
    width: 100%;
    background-color: #323061;
}

.contact-container {
    background-color: #323061;
    color: white;
}


/* Home page classes */
.home-info-container {
    width: 50%;
    background-color: #323061;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-diff-color {
    color: #9768d1;
}

.home-info-text {
    font-size: 30px;
    width: 100%;
    margin-top: -3%;
}

.home-btn-container {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: space-around;
}

.developer-image-home {
    width: 95%;
    height: 95%;
}

.btn {
    color: white;
    border: 2px solid white;
    background-color: #2f2f53;
}

.btn:hover {
    border: 2px solid white;
    background-color: #46466e;
}

.home-pic-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #323061;
    padding: 5%;
}


/* About page Classes */
.about-heading-container {
    width: 100%;
    text-align: center;
    color: white;
    padding: 2%;
    background-color: #1E1A48;
}

.about-text,
.exp-text,
.contact-text,
.project-text {
    font-size: 30px;
    height: 100px;
    border-bottom: 2px solid red;
    padding: 1.5%;
}

.about-diff-text {
    margin-left: 1%;
    color: #f77a52;
    margin-top: 7%;
}

.about-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E1A48;
}

.about-inner-container {
    width: 50%;
    margin-bottom: 7%;
}

.about-content {
    margin-top: 5%;
    color: white;
    border-left: 2px solid #66ff99;
    padding-left: 5%;
    font-size: 18px;
}

.icons-container {
    padding-top: 5%;
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.about-icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
    padding: 1%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    background-color: #272743;
    border: none;
}

.about-icon:hover {
    background-color: #383867;
}

.instaIcon {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.whaIcon {
    background: radial-gradient(#25d366 100%, transparent 0);
}

.linkIcon {
    background: radial-gradient(#5f5fec 100%, transparent 0);
}

.gitIcon {
    background: -webkit-radial-gradient(40% 40%, circle cover, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1));
}


/* Experience page Classes */
.exp-heading-container {
    width: 100%;
    text-align: center;
    color: white;
    padding: 2%;
    background-color: #323061;
}

.exp-content-container {
    display: flex;
    color: white;
}

.exp-pic-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323061;
    padding-top: 3%;
}

.exp-info-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323061;
    padding-top: 2%;
}

.exp-diff-text {
    color: #f77a52;
}

.exp-content {
    width: 95%;
    font-size: 18px;
}

.skill-container {
    padding-top: 3%;
    padding-bottom: 7%;
}

.skill-percent {
    width: 70%;
    height: 10px;
    margin-top: -2%;
    background-color: #0dae8e;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
}

.total-skill-percent {
    width: 30%;
    height: 10px;
    margin-top: -2%;
    background-color: rgb(188, 188, 188);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.reactSkill {
    width: 90%;
}

.totalReactSkill {
    width: 10%;
}

.vueSkill {
    width: 70%;
}

.totalVueSkill {
    width: 30%;
}

.nodeSkill {
    width: 80%;
}

.totalNodeSkill {
    width: 20%;
}

.javaSkill {
    width: 90%;
}

.totalJavaSkill {
    width: 10%;
}
.phpSkill {
    width: 80%;
}
.totalPhpSkill {
    width: 20%;
}

p {
    text-align: justify;
}

.developer-image-exp {
    width: 80%;
    height: 80%;
}


/* Project page Classes */
.project-heading-container {
    width: 100%;
    text-align: center;
    color: white;
    padding: 2%;
    background-color: #1E1A48;
}

.project-content-container {
    padding: 5%;
    background-color: #1E1A48;
}

.project-card-container {
    color: white;
    background-color: #1E1A48;
    border: none;
}

.project-card-inner-container {
    width: 100%;
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1E1A48;
    border-top: 1px solid white;
}

.project-desc-container {
    width: 100%;
    padding: 2%;
}

.project-desc {
    padding: 2%;
    text-align: justify;
    font-size: 21px;
}

.project-name {
    padding: 2%;
    font-size: 40px;
    color: #0dae8e;
}

.project-rating {
    padding: 2%;
}

.project-pic-container {
    width: 20%;
    text-align: center;
    padding: 3%;
}

.project-image {
    height: 200px;
    width: 180px;
}

.project-name-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Contact page Classes */

.contact-btn {
    width: 100%;
    height: 40px;
    border-radius: 15px;
    border: 2px solid white;
    background-color: #0f0f41;
    color: white;
}

.contact-btn:hover {
    background-color: #262644;
}

.contact-us-color {
    color: #fc7d49;
}

.contact-details-color {
    color: #0eeaff;
}

.contact-heading-container {
    width: 100%;
    text-align: center;
    color: white;
    padding: 2%;
    background-color: #323061;
}

.contact-form-container {
    background-color: #080829;
    border-radius: 5px;
}

.contact-field {
    background-color: #21214B;
    color: white;
    border: none;
    border-radius: 5px;
}

.contact-field-text-color {
    color: #F6B4A5;
}
.mode-toggler-container {
    display: none;
}

/* LIGHT MODE */
/* App page containers */
.navbar-image-light {
    width: 60%;
    height: 80%;
    margin-left: 15%;
}

.nav-main-container-light {
    background-color: #eaeaea;
}

.nav-container-light {
    margin-left: 6%;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.nav-container-light li a {
    display: inline-block;
}

.nav-container-light li {
    width: 20%;
    height: 80%;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
}

.nav-container-light li:hover {
    color: white;
    background-color: #d7d7d7;
    border-radius: 15px;
    width: 20%;
    height: 100%;
}

.home-container-light {
    width: 100%;
    background-color: #110F2B;
    display: flex;
    justify-content: space-evenly;
    color: white;
}

/* Home page classes */
.home-info-container-light {
    width: 50%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-info-text-light {
    font-size: 30px;
    width: 100%;
    margin-top: -3%;
    color: black;
}

.home-diff-color-light {
    color: #5d25a2;
}

.home-btn-container-light {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: space-around;
}

.developer-image-home-light {
    width: 95%;
    height: 95%;
}

.btn-light {
    color: white;
    border: 2px solid black;
    background-color: #2f2f53;
}

.btn-light:hover {
    border: 2px solid black;
    color: white;
    background-color: #46466e;
}

.home-pic-container-light {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 5%;
}


/* About page Classes */
.about-heading-container-light {
    width: 100%;
    text-align: center;
    color: rgb(42, 1, 42);
    padding: 2%;
    background-color: #eaeaea;
}
.about-container-light {
    background-color: #eaeaea;
}

.about-text-light,
.exp-text-light,
.contact-text-light,
.project-text-light {
    font-size: 30px;
    height: 100px;
    border-bottom: 2px solid red;
    padding: 1.5%;
}

.about-diff-text-light {
    margin-left: 1%;
    color: #f77a52;
    margin-top: 7%;
}

.about-content-container-light {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
}

.about-inner-container-light {
    width: 50%;
    margin-bottom: 7%;
}

.about-content-light {
    margin-top: 5%;
    color: black;
    border-left: 2px solid #0acb4a;
    padding-left: 5%;
    font-size: 18px;
}

.icons-container-light {
    padding-top: 5%;
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.about-icon-light {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
    padding: 1%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    background-color: #272743;
    border: none;
    color: white;
}

.about-icon-light:hover {
    background-color: #383867;
}

.instaIcon-light {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.whaIcon-light {
    background: radial-gradient(#25d366 100%, transparent 0);
}

.linkIcon-light {
    background: radial-gradient(#5f5fec 100%, transparent 0);
}

.gitIcon-light {
    background: -webkit-radial-gradient(40% 40%, circle cover, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1));
}


/* Experience page Classes */
.exp-heading-container-light {
    width: 100%;
    text-align: center;
    color: rgb(42, 1, 42);
    padding: 2%;
    background-color: #ffffff;
}

.exp-container-light {
    background-color: #323061;
}

.exp-content-container-light {
    display: flex;
    color: black;
}

.exp-pic-container-light {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding-top: 3%;
}

.exp-info-container-light {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding-top: 2%;
}

.exp-diff-text-light {
    color: #f77a52;
}

.exp-content-light {
    width: 95%;
    font-size: 18px;
}

.skill-container-light {
    padding-top: 3%;
    padding-bottom: 7%;
}

.skill-percent-light {
    width: 70%;
    height: 10px;
    margin-top: -2%;
    background-color: #0dae8e;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
}

.total-skill-percent-light {
    width: 30%;
    height: 10px;
    margin-top: -2%;
    background-color: rgb(188, 188, 188);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.reactSkill-light {
    width: 90%;
}

.totalReactSkill-light {
    width: 10%;
}

.vueSkill-light {
    width: 70%;
}

.totalVueSkill-light {
    width: 30%;
}

.nodeSkill-light {
    width: 80%;
}

.totalNodeSkill-light {
    width: 20%;
}

.javaSkill-light {
    width: 90%;
}

.totalJavaSkill-light {
    width: 10%;
}
.phpSkill-light {
    width: 80%;
}
.totalPhpSkill-light {
    width: 20%;
}

p {
    text-align: justify;
}

.developer-image-exp-light {
    width: 80%;
    height: 80%;
}


/* Project page Classes */
.project-heading-container-light {
    width: 100%;
    text-align: center;
    color: rgb(42, 1, 42);
    padding: 2%;
    background-color: #eaeaea;
}

.project-container-light {
    width: 100%;
    background-color: #eaeaea;
}

.project-content-container-light {
    padding: 5%;
    background-color: #eaeaea;
}

.project-card-container-light {
    color: black;
    background-color: #eaeaea;
    border: none;
}

.project-card-inner-container-light {
    width: 100%;
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea;
    border-top: 1px solid white;
}

.project-desc-container-light {
    width: 100%;
    padding: 2%;
}

.project-desc-light {
    padding: 2%;
    text-align: justify;
    font-size: 21px;
}

.project-name-light {
    padding: 2%;
    font-size: 40px;
    color: #0dae8e;
}

.project-rating-light {
    padding: 2%;
}

.project-pic-container-light {
    width: 20%;
    text-align: center;
    padding: 3%;
}

.project-image-light {
    height: 200px;
    width: 180px;
}

.project-name-btn-container-light {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Contact page Classes */

.contact-btn-light {
    width: 100%;
    height: 40px;
    border-radius: 15px;
    border: none;
    background-color: #b1b1b1;
    color: black;
}

.contact-container-light {
    background-color: #ffffff;
    color: white;
}

.contact-btn-light:hover {
    background-color: #999999;
}

.contact-us-color-light {
    color: #fc7d49;
}

.contact-details-color-light {
    color: #058b97;
}

.contact-heading-container-light {
    width: 100%;
    text-align: center;
    color: rgb(42, 1, 42);
    padding: 2%;
    background-color: #ffffff;
}

.contact-form-container-light {
    border-radius: 5px;
    color: black;
}

.contact-field-light {
    color: black;
    border-radius: 5px;
}
.contact-text-container-light {
    color: black;
}

.contact-field-text-color-light {
    color: #54261c;
}
.skill-name {
    margin-top: 2%;
}
.mode-toggler-container-light {
    display: none;
}

/* Responsiveness Dark Mode*/
@media only screen and (max-width: 992px) {
    .change-theme-text {
        display: none;
    }
    .mode-toggler-container {
        display: flex;
        width: 15vh;
        justify-content: space-between;
    }

    .change-theme-tip {
        display: none;
    }

    .project-card-container {
        border: none;
        margin-top: 0.1%;
        width: 100%;
    }

    .project-card-inner-container {
        width: 100%;
        display: inline;
        padding-left: 0%;
    }

    .project-content-container {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .project-desc-container {
        padding-bottom: 10%;
    }

    .project-image {
        height: 180px;
        width: 160px;
    }

    .project-pic-container {
        width: 100%;
        text-align: center;
        padding-top: 10%;
    }

    .project-name {
        font-size: 20px;
    }

    .project-desc {
        font-size: 18px;
    }

    .about-text,
    .exp-text,
    .contact-text,
    .project-text {
        padding: 2.7%;
    }

    .contact-text-container {
        margin-top: -7%;
    }

    .contact-form-container {
        padding-top: 0%;
        margin-top: 10%;
    }

    .about-inner-container {
        width: 100%;
    }

    .exp-content-container {
        display: inline;
    }

    .exp-content {
        padding-top: 5%;
        padding-right: 3%;
        width: 100%;
    }
    .nav-container li {
        width: 100%;
        text-align: left;
        margin-left: 5%;
    }
    .nav-container li a {
        width: 100%;
    }
    .nav-container li:hover {
        background-color: #1E1A48;
    }

    .nav-container {
        margin-left: 0%;
    }

    .exp-pic-container {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .exp-info-container {
        width: 100%;
        padding-left: 4%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .home-container {
        display: inline;
    }

    .home-info-container {
        width: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .home-pic-container {
        width: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .navbar-image {
        width: 150%;
        height: 150%;
    }

    .home-btn-container {
        margin-bottom: 7%;
    }

    .about-content-container {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    .about-content {
        margin-top: 7%;
        margin-left: 5%;
        padding-bottom: 5%;
        padding-right: 3%;
    }

    .icons-container {
        width: 60%;
    }

    .about-text {
        padding: 2.4%;
    }

    .skill-container {
        padding-left: 5%;
        padding-bottom: 5%;
    }

    .exp-info-container h1 {
        margin-left: 0%;
        text-align: center;
    }
}
/* Responsiveness Light Mode */
@media only screen and (max-width: 992px) {

    .project-card-container-light {
        border: none;
        margin-top: 0.1%;
        width: 100%;
    }
    .mode-toggler-container-light {
        display: flex;
        width: 15vh;
        justify-content: space-between;
    }

    .toggle-icon-light {
        color: black;
    }

    .project-card-inner-container-light {
        width: 100%;
        display: inline;
        padding-left: 0%;
    }

    .project-content-container-light {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .project-desc-container-light {
        padding-bottom: 10%;
    }

    .project-image-light {
        height: 180px;
        width: 160px;
    }

    .project-pic-container-light {
        width: 100%;
        text-align: center;
        padding-top: 10%;
    }

    .project-name-light {
        font-size: 20px;
    }

    .project-desc-light {
        font-size: 18px;
    }

    .about-text-light,
    .exp-text-light,
    .contact-text-light,
    .project-text-light {
        padding: 2.7%;
    }

    .contact-text-container-light {
        margin-top: -7%;
    }

    .contact-form-container-light {
        padding-top: 0%;
        margin-top: 10%;
    }

    .about-inner-container-light {
        width: 100%;
    }

    .exp-content-container-light {
        display: inline;
    }

    .exp-content-light {
        padding-top: 5%;
        padding-right: 3%;
        width: 100%;
    }

    .nav-container-light {
        margin-left: 0%;
    }

    .exp-pic-container-light {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .exp-info-container-light {
        width: 100%;
        padding-left: 4%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .home-container-light {
        display: inline;
    }

    .home-info-container-light {
        width: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .home-pic-container-light {
        width: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .navbar-image-light {
        width: 150%;
        height: 150%;
    }

    .nav-container-light li {
        width: 100%;
        text-align: left;
        margin-left: 5%;
    }
    .nav-container-light li:hover {
        background-color: #eaeaea;
    }

    .home-btn-container-light {
        margin-bottom: 7%;
    }

    .about-content-container-light {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    .about-content-light {
        margin-top: 7%;
        margin-left: 5%;
        padding-bottom: 5%;
        padding-right: 3%;
    }

    .icons-container-light {
        width: 60%;
    }

    .about-text-light {
        padding: 2.4%;
    }

    .skill-container-light {
        padding-left: 5%;
        padding-bottom: 5%;
    }

    .exp-info-container-light h1 {
        margin-left: 0%;
        text-align: center;
    }
}